import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  style,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { CarouselItemDirective } from './carousel-item.directive';
import { CarouselItemElementDirective } from './carousel-item-element.directive';
import { MainTemplate } from '../../models/template';
import { TemplateService } from '../../services';
import { take } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'carousel-list',
  exportAs: 'carousel-list',
  templateUrl: './carousel-list.component.html',
  styleUrls: ['./carousel-list.component.scss'],
})
export class CarouselListComponent implements AfterViewInit {
  @ContentChildren(CarouselItemDirective)
  items: QueryList<CarouselItemDirective>;

  @ViewChildren(CarouselItemElementDirective, { read: ElementRef })
  private itemsElements: QueryList<ElementRef>;

  @ViewChild('carousel') private carousel: ElementRef;
  @Input() timing = '250ms ease-in';
  @Input() showControls = true;
  @Input() itemsPerPage: 4 | 6 = 4;
  private player: AnimationPlayer;
  private itemWidth: number;
  private currentSlide = 0;
  public mainTemplate!: MainTemplate;
  public rigrhtArrowColorStyle = {};
  public lefttArrowColorStyle = {};
  carouselWrapperStyle = {};
  carouselLiStyle = {};

  constructor(
    private builder: AnimationBuilder,
    private readonly templateService: TemplateService
  ) {}

  private buildAnimation(offset: number) {
    return this.builder.build([
      animate(this.timing, style({ transform: `translateX(-${offset}px)` })),
    ]);
  }

  /**
   * Progresses the carousel forward by 1 slide.
   */
  next() {
    // if (this.currentSlide + 1 === this.items.length) return;
    this.currentSlide = (this.currentSlide + 1) % this.items.length;
    this.transitionCarousel();
  }

  /**
   * Regresses the carousel backwards by 1 slide.
   */
  prev() {
    // if (this.currentSlide === 0) return;

    this.currentSlide =
      (this.currentSlide - 1 + this.items.length) % this.items.length;
    this.transitionCarousel();
  }

  ngAfterViewInit() {
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.mainTemplate = template;
        this.rigrhtArrowColorStyle = {
          borderRightColor: `${this.mainTemplate.globalVariables.arrowsColor?.arrows}`,
        };
        this.lefttArrowColorStyle = {
          borderLeftColor: `${this.mainTemplate.globalVariables.arrowsColor?.arrows}`,
        };
      });
    this.reSizeCarousel();
  }

  /**
   * Listens for changes to the viewport size and triggers a re-sizing of the carousel.
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.reSizeCarousel();
  }

  /**
   * Re-sizes the carousel container and triggers `this.transitionCarousel()` to reset the childrens' positions.
   *
   * For use on initial load, and when changing viewport size.
   */
  reSizeCarousel(): void {
    // re-size the container
    this.itemWidth =
      this.itemsElements.first.nativeElement.getBoundingClientRect().width;
    this.carouselWrapperStyle = {
      width: `${this.itemWidth}px`,
      height: `27vh`,
    };
    this.carouselLiStyle = {
      width: `${this.itemWidth}px`,
      padding: `10px`,
    };

    // trigger a fresh transition to the current slide to reset the position of the children
    this.transitionCarousel();
  }

  /**
   * Animates the carousel to the currently selected slide.
   *
   * **You must set `this.currentSlide` before calling this method, or it will have no effect.**
   */
  transitionCarousel() {
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }
}
