import { HttpClient, HttpParams } from '@angular/common/http';
import { Directive } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, lastValueFrom, map, of } from 'rxjs';
import * as uuid from 'uuid';
import { SnackbarComponent } from '../components';

@Directive({})
export class UniversalConnectorVersionTwoDirective {
  public url: string = '';

  public crm: string = '';

  public dateRange: string = '';

  public patronId: string = '';

  constructor(
    connectorUrl: string,
    crm: string,
    dateRange: string,
    private http: HttpClient,
    private readonly snackBar: MatSnackBar
  ) {
    this.url = connectorUrl;
    if (!crm) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: 'error',
        duration: 5000,
        horizontalPosition: 'end',
        data: {
          type: 'error',
          message: 'crm name is requiered',
        },
      });
    }
    this.crm = crm.toLocaleLowerCase();
    this.dateRange = dateRange;
  }

  public getPatronProfile(patronId: string) {
    return lastValueFrom(
      this.http.get(`${this.url}/api/Player/get/${this.crm}/${patronId}`)
    );
  }

  public getPlayerBalances(patronId: string): Promise<any> {
    return lastValueFrom(
      this.http.get(`${this.url}/api/Balance/get/${this.crm}/${patronId}`)
    );
  }

  public getPlayerBalancesWinLossYears(patronId: string): Promise<any> {
    return lastValueFrom(
      this.http.get(
        `${this.url}/api/Balance/winlossyears/${this.crm}/${patronId}`
      )
    );
  }

  public getPlayerBalancesWinLoss(
    patronId: string,
    years: string,
    deliveryMethod: string
  ): Promise<any> {
    const queryParams = new URLSearchParams({
      years: years.toString(),
      deliveryMethod: deliveryMethod,
    }).toString();

    return lastValueFrom(
      this.http.get(
        `${this.url}/api/Balance/winloss/${this.crm}/${patronId}?${queryParams}`
      )
    );
  }

  public getPlayerBalancesWinLoss1(
    patronId: string,
    years: string,
    deliveryMethod: string
  ): Observable<any> {
    const url = `${this.url}/api/Balance/winloss/${this.crm}`;

    let params = new HttpParams();
    params = params.append('patronId', patronId);
    params = params.append('years', years);
    params = params.append('deliveryMethod', deliveryMethod);

    return this.http.get<any>(url, { params });
  }

  public updatePlayerProfile(patronId: string, type: string, body: any) {
    return lastValueFrom(
      this.http.put(
        `${this.url}/api/Player/${type}/put/${this.crm}/${patronId}`,
        body
      )
    );
  }

  public getPlayerActivity(patronId: string) {
    return lastValueFrom(
      this.http.get(
        `${this.url}/api/Activity/get/${
          this.crm
        }/${patronId}${this.getDateRange(this.dateRange)}`
      )
    );
  }

  private getDateRange(dateRange: string) {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const sevenDays = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const sevenDaysYear = sevenDays.getFullYear();
    const sevenDaysMonth = String(sevenDays.getMonth() + 1).padStart(2, '0');
    const sevenDaysDay = String(sevenDays.getDate()).padStart(2, '0');

    switch (dateRange) {
      case 'perDay':
        return `?from=${year}-${month}-${day}&to=${year}-${month}-${day}`;
      case 'sevenDays':
        return `?from=${sevenDaysYear}-${sevenDaysMonth}-${sevenDaysDay}&to=${year}-${month}-${day}`;
      case 'currentMonth':
        return `?from=${year}-${month}-01&to=${year}-${month}-${day}`;
      default:
        return `?from=${year}-${month}-${day}&to=${year}-${month}-${day}`;
    }
  }

  public doBalanceAdjustment(
    patronId: string,
    points: string | number,
    balance: any,
    type: 'D' | 'C',
    balanceToUpdate: any
  ) {
    const body = {
      TransactionId: '',
      Id: uuid.v4(),
      TransactionType: type === 'C' ? 'Deposit' : 'Withdraw',
      AccountId: balanceToUpdate.id,
      AccountType: balanceToUpdate.type,
      Amount: points.toString(),
      LocationType: 'POS',
      LocationId: 'MCD',
      Pin: '1234',
    };

    return lastValueFrom(
      this.http
        .post(
          `${this.url}/api/Balance/transfer/post/${this.crm}/${patronId}`,
          body
        )
        .pipe(
          map((res: any) => {
            return {
              result: res?.message,
              errorDetails: res?.errors?.[0]?.message,
            };
          })
        )
    );
  }

  public getCmsOffersList() {
    return this.http
      .get(`${this.url}/api/Coupon/get/${this.crm}/${this.patronId}`)
      .pipe(map(({ coupons }: any) => coupons));
  }

  public getCmsDrawingList() {
    return this.http
      .get(
        `${this.url}/api/Balance/drawing-account/${this.crm}/${this.patronId}`
      )
      .pipe(map(({ drawings }: any) => drawings));
  }

  public redeemOffers(offer: any) {
    return this.http.post(
      `${this.url}/api/Coupon/transfer/post/${this.crm}/${this.patronId}`,
      offer
    );
  }
}
