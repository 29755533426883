import { Component, Input, OnInit } from '@angular/core';
import { StyleService, TemplateService } from '../../services';
import { take } from 'rxjs';
import { MainTemplate, TemplateSetting } from '../../models/template';
import { ChooseRewardItem } from '../../models/chooseReward';

@Component({
  selector: 'app-choose-reward-card',
  templateUrl: './choose-reward-card.component.html',
  styleUrls: ['./choose-reward-card.component.scss'],
})
export class ChooseRewardCardComponent implements OnInit {
  @Input() public tab!: TemplateSetting;
  @Input() public accessRequestId: string = '';
  @Input() public campaign!: ChooseRewardItem;

  public isLoading = false;
  public mainTemplate!: MainTemplate;

  constructor(
    private readonly templateService: TemplateService,
    public readonly styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.isLoading = false;
        this.mainTemplate = template;
      });
  }
}
