<section class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">
  <ul class="carousel-inner" #carousel>
    <li
      *ngFor="let item of items"
      class="carousel-item"
      [ngStyle]="carouselLiStyle"
    >
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
  </ul>
</section>
<ng-container *ngIf="showControls">
  <div
    class="carousel__prev arrow-right"
    (click)="prev()"
    [ngStyle]="rigrhtArrowColorStyle"
  ></div>
  <div
    class="carousel__next arrow-left"
    (click)="next()"
    [ngStyle]="lefttArrowColorStyle"
  ></div>
</ng-container>
