<ng-container *ngIf="isLoaded">
  <div class="ksk-modal-wrapper">
    <div class="ksk-modal-close" (click)="closeModal()"></div>
    <div
      [ngStyle]="
        styleService.getCloseButtonStyles(
          tab.configurations.cardOfferDetails?.closeButton
        )
      "
      class="ksk-modal-close-button"
      (click)="closeModal()"
    ></div>
    <div class="ksk-modal-content" *ngIf="isLoaded">
      <div class="offer" *ngIf="isInteraction === 'GameInteraction'">
        <iframe
          class="offer-iframe"
          [src]="getIframeUrl() | safeUrl"
          frameborder="0"
        ></iframe>
      </div>

      <div
        class="offer"
        *ngIf="isInteraction === 'None' && !reward.isMystery && !isSwapEnabled"
      >
        <div class="offer-header">
          <div class="offer-img-wrapper">
            <img
              [src]="reward.imageUrl"
              class="offer-img"
              [ngStyle]="
                styleService.getStyleRule(undefined, undefined, [
                  tab.configurations.cardOfferDetails.imageBorder
                ])
              "
            />
          </div>
          <div
            class="offer-info"
            [ngClass]="{ hidden: !reward.hasOnScreenDeliveryOption }"
            [ngStyle]="
              styleService.getStyleRule(
                undefined,
                [mainTemplate.globalVariables.backgroundSecondary],
                [mainTemplate.globalVariables.borders]
              )
            "
          >
            <ng-container
              *ngIf="!!offerQr && (reward?.customProperties?.showQR ?? true)"
            >
              <qrcode
                [qrdata]="offerQr"
                [width]="styleService.isQt3Kiosk ? 135 : 270"
                [margin]="0"
                [colorLight]="'#00000000'"
                [ngStyle]="
                  styleService.getStyleRule(undefined, [
                    tab.configurations.cardOfferDetails.qrCodeBackground
                  ])
                "
              ></qrcode>
            </ng-container>
            <p
              class="offer-qr-text"
              [ngStyle]="
                styleService.getStyleRule([
                  tab.configurations.cardOfferDetails.printDescription,
                  undefined,
                  'heading4'
                ])
              "
            >
              <span *ngIf="reward?.customProperties?.rewardActionText?.length">
                {{
                  reward.customProperties.rewardActionText
                    | conditionalUppercase
                      : styleService.getUppercaseValue(
                          tab.configurations.cardOfferDetails.printDescription
                        )
                }}</span
              >
            </p>
            <button
              *ngIf="
                rewardRedemptionButtonState() !== redemptionButtonState.None
              "
              class="offer-qr-btn"
              (click)="executeRewardAction()"
              [ngStyle]="
                styleService.getStyleRule(
                  [printRedeemButtonStyles?.text],
                  [printRedeemButtonStyles?.background],
                  [printRedeemButtonStyles?.border]
                )
              "
            >
              {{
                printRedeemButtonTitle
                  | conditionalUppercase
                    : styleService.getUppercaseValue(
                        printRedeemButtonStyles?.text
                      )
              }}
            </button>
            <button
              *ngIf="
                rewardRedemptionButtonState() === redemptionButtonState.None &&
                reward?.customProperties?.showPrintButton !== false
              "
              class="offer-qr-btn"
              (click)="checkPrintPage()"
              [ngStyle]="
                styleService.getStyleRule(
                  [printRedeemButtonStyles?.text],
                  [printRedeemButtonStyles?.background],
                  [printRedeemButtonStyles?.border]
                )
              "
            >
              {{
                printButtonText
                  | conditionalUppercase
                    : styleService.getUppercaseValue(
                        printRedeemButtonStyles?.text
                      )
              }}
            </button>

            <button
              *ngIf="reward?.swaps?.rewards?.length"
              class="offer-qr-btn offer-swap-btn"
              (click)="isSwapEnabled = true"
              [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.swapOffer?.buttonTradeConfig?.text],
                  [tab.configurations.swapOffer?.buttonTradeConfig?.background],
                  [tab.configurations.swapOffer?.buttonTradeConfig?.border]
                )
              "
            >
              {{
                tab.configurations.swapOffer.buttonTrade
                  | conditionalUppercase
                    : styleService.getUppercaseValue(
                        tab.configurations.swapOffer?.buttonTradeConfig?.text
                      )
              }}
            </button>
          </div>
        </div>
        <h1
          class="offer-name"
          [ngStyle]="
            styleService.getStyleRule([
              tab.configurations.cardOfferDetails.title,
              undefined,
              'heading2'
            ])
          "
        >
          {{
            reward.name
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tab.configurations.cardOfferDetails.title
                  )
          }}
        </h1>
        <div
          class="offer-description"
          [ngStyle]="
            styleService.getStyleRule([
              tab.configurations.cardOfferDetails.text,
              undefined,
              'mainText'
            ])
          "
          [ngClass]="{
            uppercase: styleService.getUppercaseValue(
              tab.configurations.cardOfferDetails.text
            )
          }"
          [innerHtml]="reward.description"
        ></div>
      </div>

      <div class="offer" *ngIf="isSwapEnabled">
        <div *ngIf="!isSwapConfirmed" class="swap-select">
          <div
            class="swap-select__title"
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.swapOffer?.selectTitleConfig],
                undefined,
                undefined
              )
            "
          >
            {{
              tab.configurations.swapOffer?.selectTitle
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.swapOffer?.selectTitleConfig
                    )
            }}
          </div>
          <div class="swap-select__wrapper">
            <div
              class="swap-select__prev"
              *ngIf="reward?.swaps?.rewards?.length > 4"
              (click)="changeSwapPage(false)"
            ></div>
            <div
              class="swap-select__item"
              [class.swap-select__item--active]="selectSwapId === item.id"
              *ngFor="let item of swapSlides"
              (click)="selectSwapId = item.id"
            >
              <img [src]="item.imageUrl" class="swap-select__image" />
              <h5 class="swap-select__name">{{ item.name }}</h5>
            </div>
            <div
              class="swap-select__next"
              *ngIf="reward?.swaps?.rewards?.length > 4"
              (click)="changeSwapPage(true)"
            ></div>
          </div>
          <button
            class="swap-select__btn"
            [class.swap-select__btn--active]="selectSwapId?.length"
            (click)="selectSwap()"
            [ngStyle]="
              styleService.getStyleRule(
                [
                  selectSwapId?.length
                    ? tab.configurations.swapOffer?.buttonTradeConfig?.text
                    : tab.configurations.swapOffer?.buttonContinueDisableConfig
                        ?.text
                ],
                [
                  selectSwapId?.length
                    ? tab.configurations.swapOffer?.buttonTradeConfig
                        ?.background
                    : tab.configurations.swapOffer?.buttonContinueDisableConfig
                        ?.background
                ],
                [
                  selectSwapId?.length
                    ? tab.configurations.swapOffer?.buttonTradeConfig?.border
                    : tab.configurations.swapOffer?.buttonContinueDisableConfig
                        ?.border
                ]
              )
            "
          >
            <mat-icon fontIcon="check"></mat-icon>
            {{
              tab.configurations.swapOffer?.buttonContinue
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      selectSwapId?.length
                        ? tab.configurations.swapOffer?.buttonContinueConfig
                            ?.text
                        : tab.configurations.swapOffer
                            ?.buttonContinueDisableConfig?.text
                    )
            }}
          </button>
        </div>
        <div *ngIf="isSwapConfirmed" class="swap-confirm">
          <mat-icon
            class="swap-confirm__icon"
            fontIcon="check_circle"
          ></mat-icon>
          <h3
            class="swap-confirm__title"
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.swapOffer?.confirmTitleConfig?.text],
                [tab.configurations.swapOffer?.confirmTitleConfig?.background],
                [tab.configurations.swapOffer?.confirmTitleConfig?.border]
              )
            "
          >
            {{
              tab.configurations.swapOffer?.confirmTitle
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.swapOffer?.confirmTitleConfig?.text
                    )
            }}
          </h3>
        </div>
      </div>

      <div class="offer" *ngIf="isInteraction === 'None' && reward.isMystery">
        <ng-template #confirmMystryTemplate>
          <div class="offer-ok">
            <div class="offer-congrats">
              <h1
                [ngStyle]="
                  styleService.getStyleRule(
                    [tab.configurations.mysteryOffer?.okTitle],
                    undefined,
                    undefined
                  )
                "
              >
                {{
                  tab.configurations.mysteryOffer?.okTitleText?.trim()
                    | conditionalUppercase
                      : styleService.getUppercaseValue(
                          tab.configurations.mysteryOffer?.okTitle
                        )
                }}
              </h1>
              <span
                [ngStyle]="
                  styleService.getStyleRule(
                    [tab.configurations.mysteryOffer?.okText],
                    undefined,
                    undefined
                  )
                "
              >
                {{
                  tab.configurations.mysteryOffer?.okTextText?.trim()
                    | conditionalUppercase
                      : styleService.getUppercaseValue(
                          tab.configurations.mysteryOffer?.okText
                        )
                }}{{ " " + reward.name }}</span
              >
            </div>
            <div class="offers-last__item">
              <div class="offers-last__item-img-wrapper">
                <img [src]="reward.imageUrl" class="btn-ico" />
              </div>
              <div class="offers-last__item-content">
                <h3 class="offers-last__item-name">
                  {{ reward.name }}
                </h3>
              </div>
            </div>
            <button
              class="offer-claim-btn"
              (click)="confirmMysteryOk()"
              [ngStyle]="
                styleService.getStyleRule(
                  [buttonOkClaimMysteryPrizeStyles?.text],
                  [buttonOkClaimMysteryPrizeStyles?.background],
                  [buttonOkClaimMysteryPrizeStyles?.border]
                )
              "
            >
              {{
                mysteryOkButtonTitle
                  | conditionalUppercase
                    : styleService.getUppercaseValue(
                        buttonOkClaimMysteryPrizeStyles?.text
                      )
              }}
            </button>
          </div>
        </ng-template>
        <div *ngIf="!mysteryConfirmed; else confirmMystryTemplate">
          <div class="offer-header">
            <div class="offer-img-wrapper">
              <img
                [src]="reward.mysteryConfig?.imageUrl"
                class="offer-img"
                [ngStyle]="
                  styleService.getStyleRule(undefined, undefined, [
                    tab.configurations.mysteryOffer?.imageBorder
                  ])
                "
              />
            </div>
            <div class="offer-info">
              <h2
                class="offer-print-name"
                [ngStyle]="
                  styleService.getStyleRule([
                    tab.configurations.mysteryOffer?.title,
                    undefined,
                    'heading2'
                  ])
                "
              >
                {{
                  tab.configurations.mysteryOffer?.headerClaimMysteryPrizeText
                    ? (tab.configurations.mysteryOffer?.headerClaimMysteryPrizeText?.trim()
                      | conditionalUppercase
                        : styleService.getUppercaseValue(
                            tab.configurations.mysteryOffer?.title
                          ))
                    : "How to Redeem"
                }}
              </h2>
              <div
                class="offer-print-description"
                [ngStyle]="
                  styleService.getStyleRule([
                    tab.configurations.mysteryOffer?.text,
                    undefined,
                    'mainText'
                  ])
                "
                [ngClass]="{
                  uppercase: styleService.getUppercaseValue(
                    tab.configurations.mysteryOffer?.text
                  )
                }"
                [innerHtml]="
                  tab.configurations.mysteryOffer
                    ?.instructionClaimMysteryPrizeText
                    ? tab.configurations.mysteryOffer?.instructionClaimMysteryPrizeText?.trim()
                    : 'Print this offer and bring it to the Loyalty Desk to reveal your prize.'
                "
              ></div>

              <button
                class="offer-claim-btn"
                (click)="confirmMystery()"
                [ngStyle]="
                  styleService.getStyleRule(
                    [buttonClaimMysteryPrizeStyles?.text],
                    [buttonClaimMysteryPrizeStyles?.background],
                    [buttonClaimMysteryPrizeStyles?.border]
                  )
                "
              >
                {{
                  mysteryButtonTitle
                    | conditionalUppercase
                      : styleService.getUppercaseValue(
                          buttonClaimMysteryPrizeStyles?.text
                        )
                }}
              </button>
            </div>
          </div>
          <h1
            class="offer-name"
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.mysteryOffer?.title,
                undefined,
                'heading2'
              ])
            "
          >
            {{
              reward.mysteryConfig?.name
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.mysteryOffer?.title
                    )
            }}
          </h1>
          <div
            class="offer-claim-description"
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.mysteryOffer?.text,
                undefined,
                'mainText'
              ])
            "
            [ngClass]="{
              uppercase: styleService.getUppercaseValue(
                tab.configurations.mysteryOffer?.text
              )
            }"
            [innerHtml]="reward.mysteryConfig?.description"
          ></div>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #loaderTemplate>
  <div class="loader" *ngIf="isLoading">
    <mat-spinner
      *ngIf="!mainTemplate.globalVariables.loader?.imageLink?.length"
      [color]="mainTemplate.globalVariables.backgroundPrimary.color"
      [diameter]="styleService.isQt3Kiosk ? 100 : 200"
    ></mat-spinner>
    <img
      class="loader-image"
      *ngIf="mainTemplate.globalVariables.loader?.imageLink?.length"
      [src]="mainTemplate.globalVariables.loader.imageLink"
    />
  </div>
</ng-template>
