import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  OfferService,
  PrintService,
  ProfileService,
  StyleService,
  TemplateService,
  LogService,
} from '../../services';
import { Campaign, KnownReward } from '../../models';
import { MainTemplate, TemplateSetting } from '../../models/template';
import { Observable, Subscription, fromEvent, of, take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { RedemptionButtonState } from '../../models/redemption-template.model';
import { InteractionType } from '../../models/interaction';
import { ChooseRewardItem } from '../../models/chooseReward';
import { OfferModalComponent } from '../offer-modal/offer-modal.component';

@Component({
  selector: 'app-choose-modal',
  templateUrl: './choose-modal.component.html',
  styleUrls: ['./choose-modal.component.scss'],
})
export class ChooseModalComponent implements OnInit, OnDestroy {
  @Output() onDetailClose: EventEmitter<any> = new EventEmitter();
  public isLoaded = false;
  public mainTemplate!: MainTemplate;
  public tab: TemplateSetting;
  public isRewardConfirmed: boolean = false;
  public campaign: Campaign;
  private accessRequestId: string;
  private instanceId: string;
  public chooseRewardsList: ChooseRewardItem[] = [];
  public slides: any = [];
  public itemsPerPage: 4 | 6 = 4;
  private lobbyToken: string;
  private indexesOffer: [number, number];
  private isInteraction: InteractionType = InteractionType.None;

  constructor(
    public readonly styleService: StyleService,
    private readonly offerService: OfferService,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly dialog: MatDialog,
    private readonly templateService: TemplateService,
    private readonly logService: LogService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      chooseRewardsList: ChooseRewardItem[];
      tab: TemplateSetting;
      accessRequestId: string;
      instanceId: string;
      campaign: Campaign;
      lobbyToken: string;
      indexesOffer: [number, number];
    }
  ) {
    this.campaign = data.campaign;
    this.lobbyToken = data.lobbyToken;
    this.indexesOffer = data.indexesOffer;
    this.chooseRewardsList = data.chooseRewardsList;
    this.chooseRewardsList.forEach((element) => {
      if (element.iconUrl === '/images/RewardDefault.png') {
        element.iconUrl = '/assets/images/RewardDefault.png';
      }
      element.isSelected = false;
    });
    this.tab = data.tab;
    this.accessRequestId = data.accessRequestId;
    this.instanceId = data.instanceId;
  }

  ngOnInit(): void {
    this.isLoaded = false;
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.isLoaded = true;
        this.mainTemplate = template;
      });
    this.setSlides(this.chooseRewardsList, this.itemsPerPage);
  }

  chooseReward(card: any) {
    card.isSelected = !card.isSelected;
    this.chooseRewardsList.forEach((element) => {
      if (card.id !== element.id) element.isSelected = false;
    });
  }

  confirmChoosenReward() {
    let selectedReward = this.chooseRewardsList.find(
      (element) => element.isSelected === true
    );
    if (selectedReward) {
      this.offerService
        .claimChooseRaward(this.instanceId, selectedReward.id)
        .subscribe(
          () => {
            this.isRewardConfirmed = true;
            setTimeout(() => {
              this.closeModal();
              this.openDetail();
            }, 2000);
          },
          () => {
            this.snackbar.openFromComponent(SnackbarComponent, {
              panelClass: 'error',
              duration: 3000,
              horizontalPosition: 'end',
              data: {
                type: 'error',
                message: 'Reward confirm failed. Please try again',
              },
            });
          }
        );
    }
  }

  public openDetail(): void {
    let request: Observable<KnownReward> = of(this.campaign.knownReward);
    let isFirstRequest = this.campaign.knownReward === null;
    request = this.offerService.getCamapign(
      this.campaign.id,
      this.accessRequestId,
      this.campaign.schedule.schedulerId
    );

    request.pipe(take(1)).subscribe({
      next: async (rewardData) => {
        this.offerService.setDefaultCustomProperties(rewardData);
        this.campaign.knownReward = rewardData;
        const dialogRef = this.dialog.open(OfferModalComponent, {
          data: {
            indexesOffer: this.indexesOffer,
            tab: this.tab,
            offer: this.campaign,
            accessRequestId: this.accessRequestId,
            lobbyToken: this.lobbyToken,
            reward: rewardData,
            isNeedToReedemOrUpdateBalance:
              this.isInteraction === InteractionType.None && isFirstRequest,
          },
        });
        if (isFirstRequest) {
          dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              this.onDetailClose.emit();
            });
        }
      },
      error: (err) => {
        this.logService.writeLog(
          JSON.stringify(`
          Open offer details: Campaign Id - ${this.campaign.id}
          Error:${JSON.stringify(err)}`)
        );
      },
    });
  }

  isNotChosenAnyReward() {
    return !this.chooseRewardsList.some((element) => element.isSelected);
  }

  setSlides(chooseRewardsList: ChooseRewardItem[], size: number) {
    for (let i = 0; i < Math.ceil(chooseRewardsList.length / size); i++) {
      this.slides[i] = chooseRewardsList.slice(i * size, i * size + size);
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {}
}
