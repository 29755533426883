export interface Campaign {
  knownReward: KnownReward;
  id: string;
  title: string;
  imageUrl: string;
  description: string;
  termsAndConditions: string;
  isAccessible: boolean;
  isDisabled: boolean;
  isDisabledMessage: string;
  isDisabledType: string;
  hasInteractions: boolean;
  hasInteractionOnRewardsLevel: boolean;
  sequence: number;
  status: DrawingEventStatus;
  customProperties: any;
  externalCampaignId: string;
  accessibilities: Accessibility[];
  schedule: Schedule;
  isTrashold?: boolean;
  isOpening: boolean;
}

export enum DrawingEventStatus {
  Pending = 1,
  Approved = 'Approved',
  LiveEarnPeriod = 3,
  LiveActivatePeriod = 'LiveActivatePeriod',
  LiveEarnAndActivatePeriod = 'LiveEarnAndActivatePeriod',
  LiveDrawingPeriod = 6,
  Ended = 7,
}

export interface Schedule {
  setNumber: number;
  totalItems: number;
  pageNumber: number;
  pageSize: number;
  schedulerId: string;
  items: ScheduleItem[];
}

export interface ScheduleItem {
  knownReward: any;
  campaignId: string;
  status: string;
  startDate: string;
  expirationDate: string;
  schedulerId: string;
}

export interface Accessibility {
  value: string;
}

export interface CouponBody {
  message?: string;
  accessRequestId: string;
  campaigns: Campaign[];
  uiCustomization: {
    displayEndedCampaigns: boolean;
    displayEndedCampaignsExternalApi: number;
  };
}

export interface SmallReward {
  claimedOn: string;
  combinedCode?: any;
  needToClaim: boolean;
  rewardCode: string;
  securityCode: string;
}

export interface KnownReward {
  id?: string;
  isMystery?: boolean;
  mysteryConfig?: {
    description: string;
    finePrint: string;
    name: string;
    shortName: string;
    imageUrl: string;
  };
  ucWebhookType: string;
  cost: number;
  costType: string;
  customProperties: Record<string, any>;
  allowSelfRedeem?: boolean;
  campaignRewardItemId?: string;
  description?: string;
  endDateExt?: string;
  expirationDate?: string;
  externalId?: string;
  hasOnScreenDeliveryOption?: boolean;
  htmlName?: string;
  imageUrl?: string;
  issuedDate?: string;
  name?: string;
  patronName?: string;
  redeemedOn?: string;
  rewardCode: string;
  rewardPosCode?: string;
  rewardRedemptionInfo?: string;
  rewardStateExt?: string;
  rewardStates?: any[];
  combinedCode?: any;
  startDate?: string;
  startDateExt?: string;
  swaps?: any;
  termsAndConditions?: string;
  instanceId?: string;
  reward?: any;
  stepType?: 'CimGame' | 'ChooseReward';
}
