<ng-container *ngIf="!isLoading">
  <div class="offers-last__item">
    <div class="offers-last__item-img-wrapper">
      <img [src]="campaign.iconUrl" class="btn-ico" />
    </div>
    <div class="offers-last__item-content">
      <h3 class="offers-last__item-name">
        {{ campaign.name }}
      </h3>
    </div>
  </div>
</ng-container>
