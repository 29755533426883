<ng-container *ngIf="!isLoading">
  <div
    class="offers-last__item"
    (click)="openDetail()"
    [ngClass]="{
      trashold:
        campaign.isTrashold || campaign.status === drawingEventStatus.Approved
    }"
    [ngStyle]="
      styleService.getStyleRule(
        undefined,
        [tab.configurations.cardOffer.background],
        [
          tab.configurations.cardOffer.border,
          mainTemplate.globalVariables.borders
        ]
      )
    "
  >
    <div class="offers-last__item-img-wrapper">
      <img
        [src]="
          campaign.knownReward
            ? campaign.knownReward.isMystery
              ? campaign.knownReward.mysteryConfig?.imageUrl
              : campaign.knownReward.imageUrl
            : campaign.imageUrl
        "
        class="btn-ico"
      />
    </div>
    <div class="offers-last__item-content">
      <h3
        class="offers-last__item-name"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.cardOffer.title,
            undefined,
            'heading4'
          ])
        "
      >
        {{
          campaign.title
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.cardOffer.title
                )
        }}
      </h3>
      <div class="offers-last__item-content-bottom">
        <div class="offers-last__item-line"></div>
        <button
          class="offers-last__item-btn"
          [ngStyle]="
            styleService.getStyleRule(
              [tab.configurations.cardOffer.buttonOfferDetails.text],
              [tab.configurations.cardOffer.buttonOfferDetails.background],
              [tab.configurations.cardOffer.buttonOfferDetails.border]
            )
          "
        >
          {{
            tab.configurations.cardOffer.buttonOfferDetailsText
              ? tab.configurations.cardOffer.buttonOfferDetailsText
              : ("Offer detail"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardOffer.buttonOfferDetails.text
                    ))
          }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
