<ng-container *ngIf="isLoaded">
  <ng-template #rewardConfirmed>
    <div class="ksk-modal-wrapper">
      <div class="centered">
        <div class="content">
          <mat-icon fontIcon="check_circle"></mat-icon>
          <p
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.chooseOffer?.confirmedChooseReward],
                undefined,
                undefined
              )
            "
          >
            {{
              tab.configurations.chooseOffer?.confirmedChooseRewardText
                ? (tab.configurations.chooseOffer?.confirmedChooseRewardText
                  | conditionalUppercase
                    : styleService.getUppercaseValue(
                        tab.configurations.chooseOffer?.confirmedChooseReward
                      ))
                : "Confirmed"
            }}
          </p>
        </div>
      </div>
    </div>
  </ng-template>
  <div
    class="ksk-modal-wrapper"
    *ngIf="!isRewardConfirmed; else rewardConfirmed"
  >
    <div class="ksk-modal-close" (click)="closeModal()"></div>
    <div
      [ngStyle]="
        styleService.getCloseButtonStyles(
          tab.configurations.cardOfferDetails?.closeButton
        )
      "
      class="ksk-modal-close-button"
      (click)="closeModal()"
    ></div>

    <h3
      class="choose-header"
      [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.chooseOffer?.headerChooseReward],
          undefined,
          undefined
        )
      "
    >
      {{
        tab.configurations.chooseOffer?.headerChooseRewardText
          ? (tab.configurations.chooseOffer?.headerChooseRewardText
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.chooseOffer?.headerChooseReward
                ))
          : "Select your new offer:"
      }}
    </h3>
    <carousel-list [showControls]="true" [itemsPerPage]="itemsPerPage">
      <ng-container *ngFor="let slide of slides">
        <ng-container *carouselItem>
          <div [ngClass]="'card-list-' + itemsPerPage">
            <div
              *ngFor="let card of slide"
              [ngClass]="{
                'card-selected': card.isSelected,
                'card-notselected': !card.isSelected
              }"
              [ngStyle]="
                card.isSelected
                  ? styleService.getStyleRule(undefined, undefined, [
                      tab.configurations.chooseOffer?.activeImageBorder
                    ])
                  : styleService.getStyleRule(undefined, undefined, [
                      tab.configurations.chooseOffer?.inactiveImageBorder
                    ])
              "
            >
              <app-choose-reward-card
                [tab]="tab"
                [campaign]="card"
                (click)="chooseReward(card)"
              ></app-choose-reward-card>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </carousel-list>
    <button
      mat-raised-button
      class="continue-btn"
      [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.chooseOffer?.buttonContinueChooseReward.text],
          [
            isNotChosenAnyReward()
              ? tab.configurations.chooseOffer
                  ?.buttonContinueDisableChooseReward.background
              : tab.configurations.chooseOffer?.buttonContinueChooseReward
                  .background
          ],
          [
            isNotChosenAnyReward()
              ? tab.configurations.chooseOffer
                  ?.buttonContinueDisableChooseReward.border
              : tab.configurations.chooseOffer?.buttonContinueChooseReward
                  .border
          ]
        )
      "
      (click)="confirmChoosenReward()"
      [disabled]="isNotChosenAnyReward()"
    >
      <mat-icon fontIcon="check"></mat-icon>
      {{
        tab.configurations.chooseOffer?.buttonContinueChooseRewardText
          ? (tab.configurations.chooseOffer?.buttonContinueChooseRewardText
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.chooseOffer?.buttonContinueChooseReward
                    .text
                ))
          : "Continue"
      }}
    </button>
  </div>
</ng-container>
